import React, { CSSProperties, ReactNode, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { IconLoading } from '../Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  children: ReactNode;
  htmlType?: 'submit' | 'reset' | 'button';
  type?: 'primary' | 'secondary' | 'danger' | 'link' | 'default' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  link?: boolean;
  disabled?: boolean;
  clickableWhenDisabled?: boolean;
  block?: boolean;
  waiting?: boolean;
  loading?: boolean;
  to?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

export function Button({
  children,
  htmlType = 'button',
  type = 'primary',
  size = 'md',
  link,
  disabled,
  clickableWhenDisabled = false,
  block,
  waiting,
  loading,
  to,
  className,
  style,
  onClick,
}: Props) {
  const history = useHistory();
  const isLoading = waiting || loading;

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
    if (isLoading) {
      return;
    }

    if (disabled && !clickableWhenDisabled) {
      return;
    }

    if (to) {
      history.push(to);
    }

    if (onClick) {
      onClick(e);
    }
  };

  const btnClass = classnames(styles.btn, styles[`btn-${type}`], styles[`btn-${size}`], className, {
    [styles['btn-link']]: link,
    [styles['btn-block']]: block,
    [styles['btn-disabled']]: disabled,
  });

  return (
    <button className={btnClass} style={style} type={htmlType} onClick={handleClick}>
      <span>{children}</span>
      {isLoading ? (
        <i>
          <IconLoading />
        </i>
      ) : null}
    </button>
  );
}
