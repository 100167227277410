export const EmailError = {
  empty: 'Please Input a Email Address.',
  invalid: 'Please Input a Valid Email Address.',
  taken: 'Email address is taken.',
};

export const PasswordError = {
  empty: 'Please Input a Password.',
  confirm: 'Please confirm your Password.',
  invalid: 'Sorry Your password must 8 or more characters with a mix of letters, numbers.',
  mismatch: 'Sorry Your Password Does Not Match.',
};

export const PhoneError = {
  empty: 'Please Input a Phone Number.',
  invalid: 'Please Input a Valid Phone Number.',
};
