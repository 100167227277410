import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RouteUrl from '../../Route/RouteUrl';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { InfoForm } from './InfoForm';
import { KBAVerificationStatus } from './model';
import { saveTransUnionInfo } from '../../Action/accountConnection';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { Popup } from '../../Component/Popup';

function TransUnionInfoCollection() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [suggestion, setSuggestion] = useState('');

  const checkStatus = (userStatus: KBAVerificationStatus) => {
    switch (userStatus) {
      case KBAVerificationStatus.Locked:
        setIsModalVisible(true);
        setErrMessage('Transunion Verification Was Unsuccessful.');
        setSuggestion('Please Try Again In A Few Days');
        break;
      case KBAVerificationStatus.Negative:
        setIsModalVisible(true);
        setErrMessage('Transunion Verification Was Unsuccessful.');
        setSuggestion('Please Check The Information Filled In');
        break;
      case KBAVerificationStatus.Positive:
        history.push(RouteUrl.AccountConnections);
        break;
      default:
        console.log('status', userStatus);
        break;
    }
  };

  const handleSubmit = (TUInfo) => {
    setLoading(true);

    saveTransUnionInfo({ ...TUInfo, buildingName: '', abodeNumber: '' }).then((response: any) => {
      setLoading(false);
      checkStatus(response.status);
    });
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Get Your Credit Report" hasLogo={false}>
        <InfoForm loading={loading} handleSubmit={handleSubmit} />
      </ApplicationPanel>
      <Popup
        type="error"
        visible={isModalVisible}
        title={errMessage}
        description={suggestion}
        onClose={handleCloseModal}
        onConfirm={handleCloseModal}
      />
    </PagePanel>
  );
}

export default TransUnionInfoCollection;
