import React, { useState } from 'react';
import { Input } from '../Input';
import {
  isValidDayOfBirth,
  isValidForeName,
  isValidMiddleName,
  isValidPostcode,
  isValidSurname,
  isValidAddress,
  isValidBuildingNumber,
  isValidTitle,
  isValidTown,
} from '../validation';
import { Button } from '../../../Component/Button';

import styles from './index.module.scss';

const EMPTY_STR = '';
const EMPTY_ERR_TYPE = 'EMPTY';
const UNMATCH_ERR_TYPE = 'UNMATCH';

export function InfoForm({ handleSubmit, loading }) {
  const initTUInfo = {
    forename: 'Henry',
    middleNames: EMPTY_STR,
    surname: 'Noble',
    dateOfBirth: '1990-12-26',
    title: 'Mr',
    address1: 'Fisher Lane',
    address2: EMPTY_STR,
    buildingNumber: 'Bond Street',
    postcode: 'X9 4HJ',
    town: 'TEXT TOWN',
  };

  const [TUInfo, setTUIfo] = useState(initTUInfo);
  const [TUInfoErrMsg, setTUIfoErrMsg] = useState(initTUInfo);

  const getErrType = (validate: any, value: string) => {
    return validate(value) ? EMPTY_STR : value.length === 0 ? EMPTY_ERR_TYPE : UNMATCH_ERR_TYPE;
  };

  const onFirstNameChange = (forename: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, forename: getErrType(isValidForeName, forename) });
    setTUIfo({ ...TUInfo, forename });
  };

  const onMiddleNameChange = (middleNames: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, middleNames: getErrType(isValidMiddleName, middleNames) });
    setTUIfo({ ...TUInfo, middleNames });
  };

  const onLastNameChange = (surname: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, surname: getErrType(isValidSurname, surname) });
    setTUIfo({ ...TUInfo, surname });
  };

  const onBirthDateChange = (dateOfBirth: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, dateOfBirth: getErrType(isValidDayOfBirth, dateOfBirth) });
    setTUIfo({ ...TUInfo, dateOfBirth });
  };

  const onTitleChange = (title: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, title: getErrType(isValidTitle, title) });
    setTUIfo({ ...TUInfo, title });
  };

  const onBuildingNumberChange = (buildingNumber: string) => {
    setTUIfoErrMsg({
      ...TUInfoErrMsg,
      buildingNumber: getErrType(isValidBuildingNumber, buildingNumber),
    });
    setTUIfo({ ...TUInfo, buildingNumber });
  };

  const onAddressOneChange = (address1: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, address1: getErrType(isValidAddress, address1) });
    setTUIfo({ ...TUInfo, address1 });
  };

  const onAddressTwoChange = (address2: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, address2: getErrType(isValidAddress, address2) });
    setTUIfo({ ...TUInfo, address2 });
  };

  const onPostcodeChange = (postcode: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, postcode: getErrType(isValidPostcode, postcode) });
    setTUIfo({ ...TUInfo, postcode });
  };

  const onTownChange = (town: string) => {
    setTUIfoErrMsg({ ...TUInfoErrMsg, town: getErrType(isValidTown, town) });
    setTUIfo({ ...TUInfo, town });
  };

  const isAllInfoValid = () => {
    const forename = getErrType(isValidForeName, TUInfo.forename);
    const middleNames = getErrType(isValidMiddleName, TUInfo.middleNames);
    const surname = getErrType(isValidSurname, TUInfo.surname);
    const dateOfBirth = getErrType(isValidDayOfBirth, TUInfo.dateOfBirth);
    const title = getErrType(isValidTitle, TUInfo.title);
    const buildingNumber = getErrType(isValidBuildingNumber, TUInfo.buildingNumber);
    const address1 = getErrType(isValidAddress, TUInfo.address1);
    const address2 = getErrType(isValidAddress, TUInfo.address2);
    const postcode = getErrType(isValidPostcode, TUInfo.postcode);
    const town = getErrType(isValidTown, TUInfo.town);

    setTUIfoErrMsg({
      forename,
      middleNames,
      surname,
      dateOfBirth,
      title,
      buildingNumber,
      address1,
      address2,
      postcode,
      town,
    });

    return (
      forename === EMPTY_STR &&
      middleNames === EMPTY_STR &&
      surname === EMPTY_STR &&
      dateOfBirth === EMPTY_STR &&
      title === EMPTY_STR &&
      address1 === EMPTY_STR &&
      address2 === EMPTY_STR &&
      buildingNumber === EMPTY_STR &&
      postcode === EMPTY_STR &&
      town === EMPTY_STR
    );
  };

  const submitInfo = () => {
    if (isAllInfoValid()) {
      handleSubmit(TUInfo);
    }
  };

  return (
    <form style={{ width: '100%' }}>
      <div className={styles.container}>
        <Input
          serial="1."
          label="First Name"
          type="text"
          isRequired={true}
          value={TUInfo.forename}
          onChange={onFirstNameChange}
          errType={TUInfoErrMsg.forename}
        />
        <Input
          serial="2."
          label="Middle Name"
          type="text"
          isRequired={false}
          value={TUInfo.middleNames}
          onChange={onMiddleNameChange}
          errType={TUInfoErrMsg.middleNames}
        />
        <Input
          serial="3."
          label="Last Name"
          type="text"
          isRequired={true}
          value={TUInfo.surname}
          onChange={onLastNameChange}
          errType={TUInfoErrMsg.surname}
        />
        <Input
          serial="4."
          label="Title"
          type="text"
          isRequired={true}
          value={TUInfo.title}
          onChange={onTitleChange}
          errType={TUInfoErrMsg.title}
        />
        <Input
          serial="5."
          label="Date of Birth"
          type="date"
          isRequired={true}
          value={TUInfo.dateOfBirth}
          onChange={onBirthDateChange}
          errType={TUInfoErrMsg.dateOfBirth}
        />
        <Input
          serial="6."
          label="Building Number"
          type="text"
          isRequired={true}
          value={TUInfo.buildingNumber}
          onChange={onBuildingNumberChange}
          errType={TUInfoErrMsg.buildingNumber}
        />
        <Input
          serial="7."
          label="Address 1"
          type="text"
          isRequired={false}
          value={TUInfo.address1}
          onChange={onAddressOneChange}
          errType={TUInfoErrMsg.address1}
        />
        <Input
          serial="8."
          label="Address 2"
          type="text"
          isRequired={false}
          value={TUInfo.address2}
          onChange={onAddressTwoChange}
          errType={TUInfoErrMsg.address2}
        />
        <Input
          serial="9."
          label="Town"
          type="text"
          isRequired={false}
          value={TUInfo.town}
          onChange={onTownChange}
          errType={TUInfoErrMsg.town}
        />
        <Input
          serial="10."
          label="Post Code"
          type="text"
          isRequired={true}
          value={TUInfo.postcode}
          onChange={onPostcodeChange}
          errType={TUInfoErrMsg.postcode}
        />
      </div>
      <Button block loading={loading} onClick={submitInfo}>
        Next
      </Button>
    </form>
  );
}
