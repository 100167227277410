import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RouteUrl from '../../Route/RouteUrl';
import { IconSuccess } from '../../Component/Icon/IconSuccess';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { useCountdown } from '../../Hook/useCountdown';

import styles from './index.module.scss';

export default function KeepInTouch(): JSX.Element {
  const [countdown, startCountdown] = useCountdown(20);
  const history = useHistory();

  useEffect(() => {
    startCountdown();

    const timer = setTimeout(() => {
      history.push(RouteUrl.Profile);
    }, 1000 * 21);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PagePanel>
      <ApplicationPanel title={''} hasLogo={false}>
        <div className={styles.container}>
          <div className={styles.icon}>
            <IconSuccess />
          </div>
          <h2 className={styles.title}>THANKS</h2>
          <h3 className={styles['sub-title']}>WE WILL BE IN TOUCH</h3>
          <span className={styles.text}>
            <br/>
            <p>
              At Finexos we believe that every borrower should be treated on a fair and equal basis.
            </p>
            <p>
              In order to remove bias from credit decisions we have removed all Personally Identifiable Information (PII) before sending for a credit decision. This also improves the security of your data.
            </p>
            <p>Please keep an eye on your inbox while we review your loan application.</p>
          </span>
          <div className={styles.count}>{`${countdown}s`}</div>
          <div className={styles.links}>
            <div className={styles.link}>
              <Link to={RouteUrl.Profile}>My profile</Link>
            </div>
          </div>
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}
