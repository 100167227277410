import React, { ReactNode } from 'react';
import styles from './index.module.scss';

interface Props {
  hasLogo?: boolean;
  title?: string;
  children: ReactNode;
  backgroundImageUrl?: string;
}

export function ApplicationPanel({ children, title, hasLogo = true, backgroundImageUrl }: Props) {
  const backgroundStyle = backgroundImageUrl
    ? { backgroundImage: `url(${backgroundImageUrl})` }
    : {};

  return (
    <div className={styles.container} style={backgroundStyle}>
      <div className={styles.additionalImage1}></div>
      <div className={styles.additionalImage2}></div>
      {hasLogo && <div className={styles.logo} />}
      {title ? <div className={styles.title}>{title}</div> : null}
      {children}
    </div>
  );
}
