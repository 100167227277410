import React from 'react';
import { Icon, IconPropsWithoutChildren } from './Icon';
import { ReactComponent as IconSvg } from './icon-image/password-eye.svg';

export function IconPasswordEye(props: IconPropsWithoutChildren): JSX.Element {
  return (
    <Icon {...props}>
      <IconSvg />
    </Icon>
  );
}
