export interface Account {
  id: string;
  bankId: string;
  accountName: string;
  accountNumber: string;
  authorizationStatus: AuthorizedStatus;
}

export interface Bank {
  bankId: string;
  bankName: string;
  bankAccounts: Account[];
}

export enum AuthorizedStatus {
  Authorized = 1,
  Unauthorized = 2,
}
