import React from 'react';
import { Icon, IconPropsWithoutChildren } from './Icon';
import { ReactComponent as Error } from './icon-image/phone.svg';

export function IconPhone(props: IconPropsWithoutChildren): JSX.Element {
  return (
    <Icon {...props}>
      <Error />
    </Icon>
  );
}
