import React, { CSSProperties } from 'react';

import './Loading.scss';

interface Props {
  style?: CSSProperties;
}

export function Loading({ style }: Props) {
  return (
    <div className="lds-grid" style={{ ...style }}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
