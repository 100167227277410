import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../Util/token';
import RouteUrl from '../Route/RouteUrl';
import { allErrors, errorMessages } from './errors';
import { Popup } from '../Component/Popup';

const request = axios.create({
  baseURL: 'https://fiola.finexos.io/api/v1',
  timeout: 20000,
});

request.interceptors.request.use((config): AxiosRequestConfig => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    if (err.response) {
      const { status, statusText, data } = err.response;

      if (status === 401) {
        window.location.href = RouteUrl.Login;

        return Promise.reject(new Error(statusText));
      }

      const message = data?.message || statusText;
      const unknown = allErrors.indexOf(message) === -1;

      if (unknown) {
        Popup.error({
          title: 'Unexpected error',
          description: 'Please try again later',
        });
      }

      const definedErrorMessage = errorMessages[message];

      if (definedErrorMessage) {
        Popup.error({
          title: definedErrorMessage,
          description: 'Please try again later',
        });
      }

      const error = new Error(message);

      error.name = message;

      return Promise.reject(error);
    }

    return Promise.reject(err);
  }
);

export default request;
