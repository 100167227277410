import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CreateAccount from '../Page/CreateAccount';
import PasswordConfirmation from '../Page/PasswordConfirmation';
import Consent from '../Page/Consent';
import Home from '../Page/Home';
import Login from '../Page/Login';
import RouteUrl from './RouteUrl';
import TransUnionInfoCollection from '../Page/TransUnionInfoCollection';
import AccountConnections from '../Page/AccountConnections';
import PlaidConnection from '../Page/PlaidConnection';
import KeepInTouch from '../Page/KeepInTouch';
import ForgotPassword from '../Page/ForgotPassword';
import CreateNewPassword from '../Page/ForgotPassword/CreateNewPassword';
import { hasToken } from '../Util/token';
import LoanInterest from '../Page/LoanInterest';
import Profile from '../Page/Profile';
import DeactiveAccount from '../Page/Deactive';

const ProtectedRoute = ({ children, ...rest }): JSX.Element => {
  const isAuthenticated = hasToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: RouteUrl.Login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export function AppRouter() {
  return (
    <Switch>
      <Route exact path={RouteUrl.Home}>
        <Home />
      </Route>
      <Route exact path={RouteUrl.CreateAccount}>
        <CreateAccount />
      </Route>
      <Route exact path={RouteUrl.PasswordConfirmation}>
        <PasswordConfirmation />
      </Route>
      <ProtectedRoute exact path={RouteUrl.Consent}>
        <Consent />
      </ProtectedRoute>
      <Route exact path={RouteUrl.Login}>
        <Login />
      </Route>
      <ProtectedRoute exact path={RouteUrl.AccountConnections}>
        <AccountConnections />
      </ProtectedRoute>
      <ProtectedRoute exact path={RouteUrl.TransUnionInfoCollection}>
        <TransUnionInfoCollection />
      </ProtectedRoute>
      <ProtectedRoute exact path={RouteUrl.PlaidConnection}>
        <PlaidConnection />
      </ProtectedRoute>
      <ProtectedRoute exact path={RouteUrl.LoanInterest}>
        <LoanInterest />
      </ProtectedRoute>
      <ProtectedRoute exact path={RouteUrl.KeepInTouch}>
        <KeepInTouch />
      </ProtectedRoute>
      <Route exact path={RouteUrl.ForgotPassword}>
        <ForgotPassword />
      </Route>
      <Route exact path={RouteUrl.CreateNewPassword}>
        <CreateNewPassword />
      </Route>
      <ProtectedRoute exact path={RouteUrl.Profile}>
        <Profile />
      </ProtectedRoute>
      <ProtectedRoute exact path={RouteUrl.DeactiveAccount}>
        <DeactiveAccount />
      </ProtectedRoute>
      <Route path="*">
        <Redirect to={RouteUrl.Home} />
      </Route>
    </Switch>
  );
}
