import React from 'react';
import { Select } from 'antd';
import { IconWarning } from '../Icon/IconWarning';
import { IconPasswordEye } from '../Icon/IconPasswordEye';
import { IconPasswordEyeClose } from '../Icon/IconPasswordEyeClose';

import styles from './index.module.scss';
import { Popover } from '../Popover';
import { IconTips } from '../Icon/IconTips';

interface Props {
  isLabel?: boolean;
  placeholder?: string;
  type?: string;
  value?: string;
  label?: string;
  errMsg?: string;
  isPassword?: boolean;
  isConfirmed?: boolean;
  isPhone?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleTogglePasswordType?: () => void;
}

const { Option } = Select;

export default function FormInput(props: Props) {
  const {
    isLabel = true,
    handleChange,
    handleFocus,
    handleBlur,
    placeholder,
    type,
    label,
    value,
    errMsg,
    isPassword = false,
    isConfirmed = false,
    handleTogglePasswordType,
    isPhone = false,
  } = props;
  return (
    <div
      className={`${styles['form-item']} ${errMsg ? styles['form-item-error'] : ''} ${
        isPassword ? styles['for-item-pwd'] : ''
      }`}
    >
      {isLabel && (
        <label className={styles['form-label']}>
          {label} <span className={styles.divider}>|</span>
          <span>Required</span>
        </label>
      )}
      <div className={styles['form-content']}>
        {isPhone ? (
          <div className={styles['form-inner']}>
            <Select className={styles['form-select']} defaultValue="GB+44">
              <Option value="GB+44">GB+44</Option>
            </Select>
            <input
              className={styles['form-input']}
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
        ) : (
          <input
            className={styles['form-input']}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
        {isPassword && value && (
          <i className={styles['form-input-eye-icon']} onClick={handleTogglePasswordType}>
            {type === 'password' ? <IconPasswordEye /> : <IconPasswordEyeClose />}
          </i>
        )}
        {isPassword && !value && !isConfirmed && (
          <div className={styles['form-input-tips-icon']}>
            <Popover content="Use 8 or more characters with a mix of letters, numbers.">
              <IconTips />
            </Popover>
          </div>
        )}
        {errMsg.length > 0 && (
          <div className={styles['form-error']}>
            <i className={styles['form-input-error-icon']}>
              <IconWarning />
            </i>
            <span className={styles['form-input-error-text']}>{errMsg}</span>
          </div>
        )}
      </div>
    </div>
  );
}
