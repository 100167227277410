import React from 'react';
import { Icon, IconPropsWithoutChildren } from './Icon';
import { ReactComponent as IconSvg } from './icon-image/checkmark.svg';

export function IconCheckmark(props: IconPropsWithoutChildren): JSX.Element {
  return (
    <Icon {...props}>
      <IconSvg />
    </Icon>
  );
}
