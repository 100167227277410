import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Button } from '../../Component/Button';
import RouteUrl from '../../Route/RouteUrl';
import { isValidEmail } from '../../Util/validation';
import { forgetPassword } from '../../Action/account';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import FormInput from '../../Component/FormInput';
import { EmailError } from '../../Constant/ErrorMessage';

import styles from './index.module.scss';

const getEmailValidationResult = (value: string) => {
  if (!value) {
    return EmailError.empty;
  }

  const valid = isValidEmail(value);

  if (!valid) {
    return EmailError.invalid;
  }

  return '';
};

export default function ForgotPassword(): JSX.Element {
  const [sent, setSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const validateEmail = (): boolean => {
    const result = getEmailValidationResult(email);

    setEmailError(result);

    return !result;
  };

  const handleInputEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleFocusEmail = (): void => {
    setEmailError('');
  };

  const handleBlurEmail = (): void => {
    validateEmail();
  };

  const handleSubmit = async (): Promise<void> => {
    const emailResult = validateEmail();

    if (!emailResult) {
      return;
    }

    setIsSending(true);

    try {
      await forgetPassword({
        email,
      });
    } catch (err) {
      console.error(err.message);
      return;
    } finally {
      setIsSending(false);
    }

    setSent(true);
  };

  const handleBack = (): void => {
    setSent(false);
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Password Recovery">
        {sent ? (
          <div className={styles['sent-container']}>
            <div className={styles['sent-des']}>
              <p>If an account exists for</p>
              <p className={styles['sent-email']}>{email}</p>
              <p>we will send you instructions to reset your password.</p>
            </div>
            <Button type="primary" block to={RouteUrl.Login}>
              Sign In Again
            </Button>
            <div className={styles['sent-footer']} onClick={handleBack}>
              Didn’t receive an email
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              <p>
                To ensure your account is safe and to reset
                <br /> your password.
              </p>
              <p>
                Please enter the email address associated
                <br /> with your account.
              </p>
            </div>
            <div>
              <div className={styles.form}>
                <FormInput
                  isLabel={false}
                  errMsg={emailError}
                  placeholder="Email Address..."
                  type="text"
                  value={email}
                  handleChange={handleInputEmail}
                  handleFocus={handleFocusEmail}
                  handleBlur={handleBlurEmail}
                />
              </div>
              <Button type="primary" block loading={isSending} onClick={handleSubmit}>
                Recover Password
              </Button>
            </div>
            <div className={styles.footer}>
              <Link to={RouteUrl.Login}>Return to the Login Page</Link>
            </div>
          </div>
        )}
      </ApplicationPanel>
    </PagePanel>
  );
}
