import request from './request';
import { ConsentProvider } from './consent';

type OTPMethod = 'PHONE_NUMBER' | 'EMAIL_ADDRESS';

export function sendOTP(method: OTPMethod, subject: string) {
  return request.post('/accounts/otps', {
    otpMethod: method,
    subject,
  });
}

export function verifyOTP(method: OTPMethod, subject: string, code: string) {
  return request.post('/accounts/otp-validation', {
    otpMethod: method,
    subject,
    code,
  });
}

interface CreateAccountRequest {
  emailAddress: string;
  phoneNumber: string;
  password: string;
  rePassword: string;
  userName?: string;
  isDemo?: boolean;
}

export function createAccount(payload: CreateAccountRequest): Promise<string> {
  return request.post('/accounts/create', payload);
}

interface LoginRequest {
  email: string;
  password: string;
  provider: ConsentProvider;
}

interface LoginResponse {
  token: string;
  status: 'NOT_CHECKED' | 'CHECKED' | 'OUTDATED';
  interestSubmitStatus: 'SUBMITTED' | 'NOT_SUBMITTED';
}

export function login(payload: LoginRequest): Promise<LoginResponse> {
  return request.post('/accounts/login', payload);
}

interface ForgetPasswordRequest {
  email: string;
}

export function forgetPassword(payload: ForgetPasswordRequest): Promise<void> {
  return request.post('/accounts/forget', payload);
}

interface ResetPasswordRequest {
  password: string;
  rePassword: string;
  token: string;
}

export function resetPassword(payload: ResetPasswordRequest): Promise<void> {
  return request.post('/accounts/resetPassword', payload);
}

interface ProfileResponse {
  accountName: string;
  email: string;
  phone: string;
  role: string;
}

export function getProfile(): Promise<ProfileResponse> {
  return request.get('/accounts/me');
}

export function deleteAccount(payload): Promise<void> {
  return request.post('/accounts/deactivation', payload);
}
