import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { Button } from '../../Component/Button';
import { isValidEmail, isValidPassword } from '../../Util/validation';
import RouteUrl from '../../Route/RouteUrl';
import { login } from '../../Action/account';
import { setToken } from '../../Util/token';
import FormInput from '../../Component/FormInput';
import { EmailError, PasswordError } from '../../Constant/ErrorMessage';

import styles from './index.module.scss';

const getEmailValidationResult = (value: string) => {
  if (!value) {
    return EmailError.empty;
  }

  const valid = isValidEmail(value);

  if (!valid) {
    return EmailError.invalid;
  }

  return '';
};

const getPasswordValidationResult = (value: string) => {
  if (!value) {
    return PasswordError.empty;
  }

  const valid = isValidPassword(value);

  if (!valid) {
    return PasswordError.invalid;
  }

  return '';
};

export default function Login(): JSX.Element {
  const history = useHistory();
  const [email, setEmail] = useState<string>('user506874hg0ld@demo-example.com');
  const [emailError, setEmailError] = useState<string>('');

  const validateEmail = (): boolean => {
    const result = getEmailValidationResult(email);

    setEmailError(result);

    return !result;
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    setEmailError('');
  };
  const handleFocusEmail = (): void => {
    setEmailError('');
  };
  const handleBlurEmail = (): void => {
    validateEmail();
  };

  const [password, setPassword] = useState<string>('145a3r44H');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const blurPasswordTimer = useRef<NodeJS.Timeout>();

  const validatePassword = () => {
    const result = getPasswordValidationResult(password);

    setPasswordError(result);

    return !result;
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleFocusPassword = (): void => {
    setPasswordError('');
  };
  const handleBlurPassword = (): void => {
    clearTimeout(blurPasswordTimer.current);
    blurPasswordTimer.current = setTimeout(() => {
      validatePassword();
    }, 200);
  };
  const handleTogglePasswordType = (): void => {
    clearTimeout(blurPasswordTimer.current);
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    const emailResult = validateEmail();
    const passwordResult = validatePassword();

    if (!emailResult || !passwordResult) {
      return;
    }

    let token = '';
    let consentStatus = '';
    let interestStatus = '';

    setIsSubmitting(true);

    try {
      const response = await login({
        email,
        password,
        provider: 'FAIRVIEW',
      });

      token = response.token;
      consentStatus = response.status;
      interestStatus = response.interestSubmitStatus;
    } catch (err) {
      console.error(err.message);
      return;
    } finally {
      setIsSubmitting(false);
    }

    setToken(token);

    if (interestStatus === 'SUBMITTED') {
      history.push(RouteUrl.KeepInTouch);
      return;
    }

    if (consentStatus === 'CHECKED') {
      history.push(RouteUrl.AccountConnections);
      return;
    }

    if (consentStatus !== 'CHECKED') {
      history.push(RouteUrl.Consent);
      return;
    }
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Login">
        <div className={styles.form}>
          <FormInput
            label="Email Address"
            type="text"
            value={email}
            placeholder="Email Address..."
            handleChange={handleChangeEmail}
            handleBlur={handleBlurEmail}
            handleFocus={handleFocusEmail}
            errMsg={emailError}
          />
          <FormInput
            label="Password"
            type={passwordType}
            value={password}
            placeholder="Password..."
            isPassword={true}
            handleChange={handleChangePassword}
            handleBlur={handleBlurPassword}
            handleFocus={handleFocusPassword}
            errMsg={passwordError}
            handleTogglePasswordType={handleTogglePasswordType}
          />
        </div>
        <Button block loading={isSubmitting} onClick={handleSubmit}>
          Next
        </Button>
        <div className={styles.links}>
          <div className={styles.link}>
            <Link to={RouteUrl.ForgotPassword}>Forgot password?</Link>
          </div>
        </div>
        <div className={styles.links}>
          <div className={styles.link}>
            <Link to={RouteUrl.CreateAccount}>Don't Have An Account？</Link>
          </div>
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}
