import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { IconSuccess } from '../Icon/IconSuccess';
import { IconError } from '../Icon/IconError';
import { Button } from '../Button';

import styles from './index.module.css';

interface Props {
  type: 'success' | 'error';
  visible?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  confirmButtonText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

export function Popup({
  type,
  visible,
  title,
  description,
  confirmButtonText = 'Close',
  onClose,
  onConfirm,
  children,
}: Props): JSX.Element | null {
  const handleClose = (): void => {
    onClose && onClose();
  };

  const handleConfirm = (): void => {
    onConfirm && onConfirm();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={`${styles.container} ${styles[type]}`}>
      <div className={styles.mask} onClick={handleClose} />
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.icon}>
            {type === 'success' && <IconSuccess />}
            {type === 'error' && <IconError />}
          </div>
          <div className={styles.body}>
            {title && <div className={styles.title}>{title}</div>}
            {description && <div className={styles.description}>{description}</div>}
            {children}
          </div>
          {onConfirm && (
            <Button block onClick={handleConfirm}>
              {confirmButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

interface Options {
  title?: string;
  description?: string;
}

const target = 'popup-container';

Popup.error = (options?: Options) => {
  let $container = document.querySelector(`#${target}`);

  if (!$container) {
    $container = document.createElement('div');

    $container.id = target;

    document.body.appendChild($container);
  }

  const $target = document.createElement('div');

  $container.appendChild($target);

  const unmount = () => {
    ReactDOM.unmountComponentAtNode($target);
    $container.removeChild($target);
  };

  ReactDOM.render(
    <Popup type="error" {...options} onClose={unmount} onConfirm={unmount} visible />,
    $target
  );
};
