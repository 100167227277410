import React from 'react';
import { AuthorizedStatus } from '../model';
import { IconConnected } from '../../../Component/Icon/IconConnected';

import styles from './index.module.scss';

interface Account {
  id: string;
  accountNumber: string;
  authorizationStatus: AuthorizedStatus;
}

interface Props {
  accounts: Account[];
  bankName: string;
}

export function Bank({ accounts, bankName }: Props) {
  const getConnectStatus =
    accounts.length ===
    accounts.filter((account) => account.authorizationStatus === AuthorizedStatus.Authorized)
      .length;

  return (
    <div className={`${styles.container} ${getConnectStatus ? styles.connected : ''}`}>
      <div className={styles.name}>{bankName}</div>
      {accounts.map((account) => {
        return (
          <div className={styles.account}>
            <span>{account.accountNumber}</span>
            {getConnectStatus && (
              <i className={styles['connected-icon']}>
                <IconConnected />
              </i>
            )}
          </div>
        );
      })}
    </div>
  );
}
