import React, { ReactNode } from 'react';
import styles from './index.module.scss';

interface Props {
  children: ReactNode;
}

export function PagePanel({ children }: Props) {
  return (
    <div className={`${styles.page} ${styles.background}`}>
      <video autoPlay loop muted playsInline className={styles.videoBackground}>
        <source
          src="https://www.finexos.io/wp-content/uploads/2023/12/finexos-green-small-compressed.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className={styles.container}>{children}</div>
    </div>
  );
}
