import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../Component/Button';
import { Popup } from '../../Component/Popup';
import { IconWarning } from '../../Component/Icon/IconWarning';
import { useCountdown } from '../../Hook/useCountdown';
import { useInterval } from '../../Hook/useInterval';
import { isValidEmail, isValidPhone } from '../../Util/validation';
//import { sendOTP, verifyOTP } from '../../Action/account';
import routeUrl from '../../Route/RouteUrl';

import styles from './OTPValidation.module.scss';
import { ResponseError } from '../../Action/errors';
import FormInput from '../../Component/FormInput';
import { EmailError, PhoneError } from '../../Constant/ErrorMessage';

type CodeValidationError = 'empty' | 'invalid' | 'expired' | 'restricted' | '';

export type VerificationType = 'phone' | 'email';

const getEmailValidationResult = (value: string) => {
  if (!value) {
    return EmailError.empty;
  }

  const valid = isValidEmail(value);

  if (!valid) {
    return EmailError.invalid;
  }

  return '';
};

const getPhoneValidationResult = (value: string) => {
  if (!value) {
    return PhoneError.empty;
  }

  const valid = isValidPhone(value);

  if (!valid) {
    return PhoneError.invalid;
  }

  return '';
};

const getCodeValidationResult = (value: string): CodeValidationError => {
  if (!value) {
    return 'empty';
  }

  return '';
};

interface Props {
  type: VerificationType;
  onChange: (value: Record<string, string>) => void;
  mock: boolean;
}

export function OTPValidation({ type, onChange, mock = false }: Props): JSX.Element {
  const history = useHistory();
  const [email, setEmail] = useState<string>(
    mock
      ? 'test@test.com'
      : 'user' +
          Math.floor(100000 + Math.random() * 900000) +
          (Math.random() + 1).toString(36).substring(7) +
          '@demo-example.com'
  );
  const [emailError, setEmailError] = useState<string>('');

  const validateEmail = (): boolean => {
    const result = getEmailValidationResult(email);

    setEmailError(result);

    return !result;
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    setEmailError('');
  };
  const handleFocusEmail = (): void => {
    setEmailError('');
  };
  const handleBlurEmail = (): void => {
    validateEmail();
  };

  const [phone, setPhone] = useState<string>(
    mock ? '4488775544' : '' + Math.floor(1000000000 + Math.random() * 9000000000)
  );
  const [phoneError, setPhoneError] = useState<string>('');

  const validatePhone = (): boolean => {
    const result = getPhoneValidationResult(phone);

    setPhoneError(result);

    return !result;
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPhone(e.target.value);
    setPhoneError('');
  };
  const handleFocusPhone = (): void => {
    setPhoneError('');
  };
  const handleBlurPhone = (): void => {
    validatePhone();
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const codeRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState<string>(
    mock ? '000000' : '' + Math.floor(100000 + Math.random() * 900000)
  );
  const [codePlaceholderVisible, setCodePlaceholderVisible] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<CodeValidationError>('');

  const [countdown, startCountdown] = useCountdown(60);
  const [codeSentTimes, setCodeSentTimes] = useState<number>(0);
  const [lastSentAt, setLastSentAt] = useState<number>(0);
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false);

  useInterval(() => {
    setCodeSentTimes(0);
  }, 1000 * 60 * 5);

  const handleSendCode = async () => {
    let validation = true;

    if (type === 'email') {
      validation = validateEmail();
    }

    if (type === 'phone') {
      validation = validatePhone();
    }

    if (!validation) {
      return;
    }

    if (codeSentTimes > 3) {
      setCodeError('restricted');
      return;
    }

    setIsCodeSending(true);

    try {
      //const method = type === 'email' ? 'EMAIL_ADDRESS' : 'PHONE_NUMBER';
      //const value = type === 'email' ? email : phone;

      //await sendOTP(method, value);
      setCode('' + Math.floor(100000 + Math.random() * 900000));

      startCountdown();
      setCodeSentTimes(codeSentTimes + 1);
      setLastSentAt(Date.now());
    } catch (err) {
      const { name, message } = err as ResponseError;

      if (name === 'error.get-otp-too-frequency') {
        setCodeError('restricted');
        return;
      }

      console.error(message);
    } finally {
      setIsCodeSending(false);
    }
  };

  const validateCode = (): boolean => {
    const result = getCodeValidationResult(code);

    setCodeError(result);

    return !result;
  };

  const focusCodeInput = () => {
    codeRef.current.focus();
    setCodePlaceholderVisible(false);
    setCodeError('');
  };

  const handleFocusCode = (e: React.FocusEvent<HTMLInputElement>) => {
    setCodePlaceholderVisible(false);
    setCodeError('');
  };

  const handleBlurCode = () => {
    if (!code) {
      setCodePlaceholderVisible(true);
    } else {
      validateCode();
    }
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    setCodeError('');
  };

  const handleSubmit = async () => {
    let allValid = true;

    if (type === 'email') {
      const result = validateEmail();

      if (!result) {
        allValid = false;
      }
    }

    if (type === 'phone') {
      const result = validatePhone();

      if (!result) {
        allValid = false;
      }
    }

    const codeResult = validateCode();

    if (!codeResult) {
      allValid = false;
    }

    if (!allValid) {
      return;
    }

    if (lastSentAt) {
      const diff = Date.now() - lastSentAt;

      if (diff >= 1000 * 60 * 5) {
        setCodeError('expired');
        return;
      }
    }

    setIsSubmitting(true);

    try {
      //const method = type === 'email' ? 'EMAIL_ADDRESS' : 'PHONE_NUMBER';
      //const subject = type === 'email' ? email : phone;
      //await verifyOTP(method, subject, code);
    } catch (err) {
      const { name, message } = err as ResponseError;

      if (name === 'error.timed-out-code') {
        setCodeError('expired');
        return;
      }

      console.error(message);
      return;
    } finally {
      setIsSubmitting(false);
    }

    if (type === 'email') {
      onChange({
        type: 'phone',
        email,
      });

      return;
    }

    if (type === 'phone') {
      onChange({
        type: 'email',
        phone,
      });

      history.push(routeUrl.PasswordConfirmation);
    }
  };

  const closePopup = () => {
    setCodeError('');
  };

  return (
    <div>
      <div className={styles.form}>
        {type === 'email' && (
          <FormInput
            label="Email Address"
            type="text"
            value={email}
            placeholder="Email Address..."
            handleChange={handleChangeEmail}
            handleBlur={handleBlurEmail}
            handleFocus={handleFocusEmail}
            errMsg={emailError}
          />
        )}
        {type === 'phone' && (
          <FormInput
            label="Phone Number"
            type="text"
            value={phone}
            placeholder="Phone Number..."
            handleChange={handleChangePhone}
            handleBlur={handleBlurPhone}
            handleFocus={handleFocusPhone}
            errMsg={phoneError}
            isPhone={true}
          />
        )}
      </div>
      <div className={styles['code-container']}>
        <div
          className={`${styles['code-box']} ${
            codeError === 'empty' ? styles['code-box-error'] : ''
          }`}
        >
          <input
            ref={codeRef}
            className={styles['code-input']}
            type="text"
            value={code}
            onChange={handleChangeCode}
            onFocus={handleFocusCode}
            onBlur={handleBlurCode}
            autoComplete="off"
          />
          <div
            className={`${styles['code-placeholder']} ${codePlaceholderVisible ? '' : 'hidden'}`}
            onClick={focusCodeInput}
          >
            Enter One Time Password Code
          </div>
          <div className={styles['code-error']}>
            <i className={styles['code-error-icon']}>
              <IconWarning />
            </i>
            <p className={styles['code-error-text']}>Please input OTP</p>
          </div>
        </div>
        <div className={styles['form-send-btn']}>
          <Button block disabled={countdown > 0 || isCodeSending} onClick={handleSendCode}>
            {countdown ? `${countdown} seconds` : 'Send Code'}
          </Button>
        </div>
      </div>
      <div className={styles['submit-btn-box']}>
        <Button block loading={isSubmitting} onClick={handleSubmit}>
          Next
        </Button>
      </div>
      <Popup
        type="error"
        visible={codeError === 'expired'}
        title="This One Time Password has expired"
        description="Please Try Again"
        onClose={closePopup}
        onConfirm={closePopup}
      ></Popup>
      <Popup
        type="error"
        visible={codeError === 'restricted'}
        title="Send again request is made too frequently"
        description="Please come back again after 5 minutes."
        onClose={closePopup}
        onConfirm={closePopup}
      ></Popup>
    </div>
  );
}
