import React from 'react';
import classnames from 'classnames';

import styles from './Icon.module.css';

interface IconProps {
  fill?: boolean;
  spin?: boolean;
  children: React.ReactNode;
  onClick?: any;
}

export type IconPropsWithoutChildren = Omit<IconProps, 'children'>;

export function Icon({ fill = true, spin = false, children, onClick }: IconProps): JSX.Element {
  const classes = classnames(styles.icon, {
    [styles.fill]: fill,
    [styles.spin]: spin,
  });

  return (
    <span className={classes} onClick={onClick}>
      {children}
    </span>
  );
}
