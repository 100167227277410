const key = 'authorization_token';

export function setToken(value: string): void {
  window.sessionStorage.setItem(key, value);
}

export function getToken(): string {
  return window.sessionStorage.getItem(key) || '';
}

export function hasToken(): boolean {
  const token = getToken();

  return !!token;
}

export function removeToken(): void {
  window.sessionStorage.removeItem(key);
}
