import React, { useState } from 'react';

import styles from './index.module.scss';

interface Props {
  content?: string;
  children: React.ReactNode;
}

export const Popover = ({ content, children }: Props): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = (): void => {
    setVisible(!visible);
  };

  const handleMouseLeave = (): void => {
    setVisible(false);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${visible ? styles.show : ''}`}>{content}</div>
      <div className={styles.trigger} onClick={handleClick} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </div>
  );
};
