import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../Component/Button';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Step } from '../../Component/Step';
import ConnectBox from './ConnectBox';
import { getAccountConnectionStatus } from '../../Action/accountConnection';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import RouteUrl from '../../Route/RouteUrl';
import { LoadingOverlay } from '../../Component/LoadingOverlay';

import styles from './index.module.scss';

const DISCONNECT = 'YTS';
const CONNECTED = 'READY';

function AccountConnections() {
  const history = useHistory();
  const [creditReportStatus, setCreditReportStatus] = useState(DISCONNECT);
  const [openBankingStatus, setOpenBankingStatus] = useState(DISCONNECT);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAccountConnectionStatus()
      .then((response: any) => {
        setCreditReportStatus(response.creditReportStatus);
        setOpenBankingStatus(response.openBankingStatus);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const goNext = () => {
    if (creditReportStatus === DISCONNECT) {
      history.push(RouteUrl.TransUnionInfoCollection);
    }
    if (creditReportStatus === CONNECTED && openBankingStatus === DISCONNECT) {
      history.push(RouteUrl.PlaidConnection);
    }
    if (creditReportStatus === CONNECTED && openBankingStatus === CONNECTED) {
      history.push(RouteUrl.LoanInterest);
    }
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Account Connections" hasLogo={false}>
        <div className={styles.content}>
          <ConnectBox
            iconType="TU"
            connectStatus={creditReportStatus === CONNECTED}
            title="Credit Report"
          >
            <div className={styles.comment}>
              When you connect your Trans Union account to EXZT we will have access to credit
              report. This will be annoymoused before use.
            </div>
          </ConnectBox>
          <ConnectBox
            iconType="PLAID"
            connectStatus={openBankingStatus === CONNECTED}
            title="Open Banking"
          >
            <div className={styles.comment}>
              When you connect your Plaid Banking account to EXZT we will have access to bank
              accounts. This will be annoymoused before use.
            </div>
          </ConnectBox>
        </div>
        <Button block onClick={goNext}>
          Next
        </Button>
        <div className={styles['step-box']}>
          <Step total={4} current={3} />
        </div>
        {isLoading && <LoadingOverlay />}
      </ApplicationPanel>
    </PagePanel>
  );
}

export default AccountConnections;
