import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useHistory } from 'react-router-dom';
import RouteUrl from '../../Route/RouteUrl';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Bank } from './Bank';
import { Button } from '../../Component/Button';
import { isAllAuthorized } from './util';
import { getBankAccounts } from '../../Action/accountConnection';
import { getPlaidLinkToken, savePlaidPublicToken } from '../../Action/openBaking';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { Popup } from '../../Component/Popup';
import { LoadingOverlay } from '../../Component/LoadingOverlay';

import styles from './index.module.scss';

function PlaidConnection() {
  const history = useHistory();
  const [bankList, setBankList] = useState([]);
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const [plaidErrorMessage, setPlaidErrorMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const isBankAccountsAuthorized = useMemo(() => isAllAuthorized(bankList), [bankList]);

  const getBankList = useCallback(
    () =>
      getBankAccounts()
        .then((response: any) => {
          setBankList(response);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        }),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getBankList();
    getPlaidLinkToken()
      .then((data: any) => {
        setPlaidLinkToken(data.linkToken);
      })
      .catch((error) => {
        setIsModalVisible(true);
        setErrMessage('Get token failed');
      });
  }, [getBankList]);

  const plaidAuthSuccessCallback = useCallback(
    (publicToken: string) => {
      setIsLoading(true);
      savePlaidPublicToken(publicToken)
        .then(() => {
          getBankList();
        })
        .catch((error) => {
          setIsModalVisible(true);
          setErrMessage('Save token failed');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getBankList]
  );

  const plaidAuthFailedCallback = useCallback((error) => {
    const errorMessage = error?.error_message;
    if (errorMessage) {
      setPlaidErrorMessage(errorMessage);
      setIsModalVisible(true);
      setErrMessage(errorMessage);
    }
  }, []);

  const plaidConfig: PlaidLinkOptions = useMemo(
    () => ({
      token: plaidLinkToken,
      onSuccess: plaidAuthSuccessCallback,
      onExit: plaidAuthFailedCallback,
    }),
    [plaidLinkToken, plaidAuthSuccessCallback, plaidAuthFailedCallback]
  );

  const { open, ready } = usePlaidLink(plaidConfig);

  const isDisabled = useMemo(
    () => !ready || !!plaidErrorMessage || isLoading,
    [ready, plaidErrorMessage, isLoading]
  );

  const goNext = () => {
    history.push(RouteUrl.AccountConnections);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Banking Relationship" hasLogo={false}>
        <div className={styles.info}>
          Now connect your bank account. You will need to connect all your accounts through Plaid to
          complete this step.
          <br />
          Login powered by Plaid.
        </div>
        <div className={styles.container}>
          {bankList.map((bank) => (
            <Bank key={bank.bankId} accounts={bank.bankAccounts} bankName={bank.bankName} />
          ))}
        </div>
        {!isBankAccountsAuthorized && bankList.length > 0 && (
          <Button block disabled={isDisabled} onClick={() => open()}>
            Connect My Bank Account
          </Button>
        )}
        {isBankAccountsAuthorized && (
          <Button block onClick={goNext}>
            Next
          </Button>
        )}
        {isLoading && <LoadingOverlay />}
      </ApplicationPanel>
      <Popup
        type="error"
        visible={isModalVisible}
        title={errMessage}
        onClose={handleCloseModal}
        onConfirm={handleCloseModal}
      />
    </PagePanel>
  );
}

export default PlaidConnection;
