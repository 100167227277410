import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number, immediate: boolean = false): void {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (!immediate) {
      return;
    }

    savedCallback.current();
  }, [immediate]);

  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      return clearInterval(id);
    };
  }, [delay]);
}
