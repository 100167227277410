import { AuthorizedStatus } from './model';

export const isAllAuthorized = (banks: any) => {
  if (!banks.length) {
    return false;
  }
  return banks.every((bank) =>
    bank.bankAccounts.every(
      (account) => account.authorizationStatus === AuthorizedStatus.Authorized
    )
  );
};
