export function isValidForeName(name: string) {
  const reg = new RegExp(`^[A-Za-z\\s-]{1,30}$`, 'g');
  return reg.test(name);
}

export function isValidMiddleName(name: string) {
  const reg = new RegExp(`^[A-Za-z\\s-]{0,30}$`, 'g');
  return reg.test(name);
}

export function isValidSurname(name: string) {
  const reg = new RegExp(`^[A-Za-z\\s-]{2,30}$`, 'g');
  return reg.test(name);
}

export function isValidDayOfBirth(dob: string) {
  let number = new Date().getTime() - new Date(dob).getTime();
  let oneYearMs = 1000 * 60 * 60 * 24 * 365;

  return number >= oneYearMs * 18 && number <= oneYearMs * 120;
}

export function isValidBuildingNumber(buildNumber: string) {
  const reg = new RegExp(`^.{1,12}$`, `g`);
  return reg.test(buildNumber);
}

export function isValidAddress(address: string) {
  const reg = new RegExp(`^.{0,50}$`, `g`);
  return reg.test(address);
}

export function isValidTitle(title: string) {
  const reg = new RegExp(`^.{2,30}$`, `g`);
  return reg.test(title);
}

export function isValidTown(town: string) {
  const reg = new RegExp(`^.{0,25}$`, `g`);
  return reg.test(town);
}

export function isValidPostcode(postcode: string) {
  const reg =
    /^(?:([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2}))$/g;
  return reg.test(postcode);
}
