import { useState, useEffect, useMemo } from 'react';
import { Slider, Select } from 'antd';
import { useHistory } from 'react-router-dom';

import { Button } from '../../Component/Button';
import { Step } from '../../Component/Step';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { IconPlus } from '../../Component/Icon/IconPlus';
import { IconSubtract } from '../../Component/Icon/IconSubtract';
import { getLoanOptions, saveLoanPlan } from '../../Action/loanInterest';
import RouteUrl from '../../Route/RouteUrl';
import { IconSubtractDisabled } from '../../Component/Icon/IconSubtractDisabled';
import { IconPlusDisabled } from '../../Component/Icon/IconPlusDisabled';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';

import styles from './index.module.scss';

const { Option } = Select;

interface TermOptionItem {
  apr: number;
  months: number;
}

export default function LoanInterest() {
  const initLoanAmount = 7500;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [termOptions, setTermOptions] = useState<TermOptionItem[]>([]);
  const [loanAmount, setLoanAmount] = useState(initLoanAmount);
  const [purpose, setPurpose] = useState('');
  const [term, setTerm] = useState(0);
  const selectedTerm = useMemo<TermOptionItem | undefined>(() => {
    if (!termOptions) {
      return;
    }

    return termOptions.find((item) => item.months === term);
  }, [termOptions, term]);
  const [repayment, setRepayment] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const goToSection = (value: number): void => {
    setActiveSection(value);
  };

  useEffect(() => {
    getLoanOptions().then((data: any) => {
      const { purposes, terms } = data;
      setPurpose(purposes[0].name);
      setTerm(terms[0].months);
      setPurposeOptions(purposes);
      setTermOptions(terms);
      setRepayment(calculateRepayment(initLoanAmount, terms[0].apr, terms[0].months));
      setIsLoading(false);
    });
  }, [initLoanAmount]);

  const calculateRepayment = (loanAmount, apr, terms) => {
    return (loanAmount + loanAmount * apr) / terms;
  };

  const getAPr = (months: number) => {
    return termOptions.find((term) => term.months === months).apr;
  };

  const handlePurposeChange = (value: string) => {
    setPurpose(value);
  };

  const handleTermsChange = (value: number) => {
    setTerm(value);
    setRepayment(calculateRepayment(loanAmount, getAPr(value), value));
  };

  const handleSubtractLoanAmount = () => {
    const value = Math.max(Math.ceil((loanAmount - 1000) / 1000) * 1000, 1000);

    setLoanAmount(value);
    setRepayment(calculateRepayment(value, getAPr(term), term));
  };
  const handleAddLoanAmount = () => {
    const value = Math.min(loanAmount + 1000, 7500);

    setLoanAmount(value);
    setRepayment(calculateRepayment(value, getAPr(term), term));
  };
  const handleAmountChange = (value: number) => {
    setLoanAmount(value);
    setRepayment(calculateRepayment(value, getAPr(term), term));
  };

  const goNext = () => {
    setIsLoading(true);
    saveLoanPlan({ loanAmount, terms: term, purpose, estimateMonthlyRepayment: repayment }).then(
      () => {
        setIsLoading(false);
        history.push(RouteUrl.KeepInTouch);
      }
    );
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Loan Application Form" hasLogo={false}>
        <div className={styles.container}>
          <div className={activeSection === 0 ? '' : 'hidden'}>
            <section className={styles.section}>
              <div className={styles.title}>What is the purpose of this loan?</div>
              {!purpose && <Select className={styles.select} />}
              {purpose && (
                <Select
                  className={styles.select}
                  defaultValue={purpose}
                  onChange={handlePurposeChange}
                >
                  {purposeOptions.map((purpose) => (
                    <Option key={purpose.name} value={purpose.name}>
                      {purpose.name}
                    </Option>
                  ))}
                </Select>
              )}
            </section>
            <section className={styles.section}>
              <div className={styles.title}>How much money are you requesting to borrow?</div>
              <div className={styles.des}>(Between £1,000 & £7,500)</div>
              <div className={styles.amount}>£{loanAmount.toLocaleString()}</div>
              <div className={styles['slider-box']}>
                <span className={styles['slider-icon']} onClick={handleSubtractLoanAmount}>
                  {loanAmount === 1000 ? <IconSubtractDisabled /> : <IconSubtract />}
                </span>
                <div className={styles['slider-content']}>
                  <Slider
                    min={1000}
                    max={7500}
                    step={1000}
                    defaultValue={initLoanAmount}
                    value={loanAmount}
                    onAfterChange={handleAmountChange}
                  />
                  <div className={styles.range}>
                    <span>£1,000</span>
                    <span>£7,500</span>
                  </div>
                </div>
                <span className={styles['slider-icon']} onClick={handleAddLoanAmount}>
                  {loanAmount === 7500 ? <IconPlusDisabled /> : <IconPlus />}
                </span>
              </div>
            </section>
            <section className={styles.section}>
              <div className={styles.title}>Please select a term length for your loan.</div>
              {!term && <Select className={styles.select} />}
              {term > 0 && (
                <Select className={styles.select} defaultValue={term} onChange={handleTermsChange}>
                  {termOptions.map((term, index) => (
                    <Option key={term.apr} value={term.months}>
                      {term.months} months, APR {term.apr * 100}%
                    </Option>
                  ))}
                </Select>
              )}
            </section>
          </div>
          <div className={activeSection === 1 ? '' : 'hidden'}>
            <section className={styles.section}>
              <div className={styles.summary}>
                <div className={styles['summary-item']}>
                  <div className={styles['summary-title']}>What is the purpose of this loan?</div>
                  <p className={styles['summary-value']}>{purpose}</p>
                </div>
                <div className={styles['summary-item']}>
                  <div className={styles['summary-title']}>
                    How much money are you requesting to borrow?
                  </div>
                  <p className={styles['summary-value']}>£{loanAmount.toLocaleString()}</p>
                </div>
                <div className={styles['summary-item']}>
                  <div className={styles['summary-title']}>
                    Please select a term length for your loan.
                  </div>
                  <p className={styles['summary-value']}>
                    {selectedTerm
                      ? `${selectedTerm.months} months, APR ${selectedTerm.apr * 100}%`
                      : ''}
                  </p>
                </div>
              </div>
            </section>
            <section className={styles['plan-result']}>
              <div className={styles['plan-title']}>Your Loan Plan</div>
              <ul className={styles.plan}>
                <li>
                  <span>Total Amount</span>
                  <strong>£{loanAmount.toLocaleString()}</strong>
                </li>
                <li>
                  <span>Term Length</span>
                  <strong>{term} Months</strong>
                </li>
                <li className={styles['plan-primary']}>
                  <span>Monthly Repayments</span>
                  <strong>£{repayment.toLocaleString()}</strong>
                </li>
              </ul>
            </section>
          </div>
        </div>
        {activeSection === 0 ? (
          <Button block loading={isLoading} onClick={() => goToSection(1)}>
            Next
          </Button>
        ) : (
          <div className={styles['btn-group']}>
            <div className={styles['btn-item']}>
              <Button block onClick={() => goToSection(0)}>
                Back
              </Button>
            </div>
            <div className={styles['btn-item']}>
              <Button block loading={isLoading} onClick={goNext}>
                Confirm
              </Button>
            </div>
          </div>
        )}
        <div className={styles['step-box']}>
          <Step total={4} current={4} />
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}
