import React from 'react';
import { Link } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Step } from '../../Component/Step';
import { OTPValidation, VerificationType } from './OTPValidation';
import { useCreateAccountSession } from '../../Hook/useSessionStorage';
import RouteUrl from '../../Route/RouteUrl';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';

import styles from './index.module.scss';

function CreateAccount({ mock = false }) {
  const [session, setSession] = useCreateAccountSession();
  const type: VerificationType = (session.type as VerificationType) || 'email';
  const title = type === 'phone' ? 'Phone Verification' : 'Email Verification';

  const handleChange = (value): void => {
    setSession(value);
  };

  return (
    <PagePanel>
      <ApplicationPanel title={title}>
        {type === 'email' && <OTPValidation type="email" mock={mock} onChange={handleChange} />}
        {type === 'phone' && <OTPValidation type="phone" mock={mock} onChange={handleChange} />}
        <div className={styles.footer}>
          <Link to={RouteUrl.Login}>Already Have An Account?</Link>
        </div>
        <div className={styles['step-box']}>
          <Step total={4} current={1} />
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}

export default CreateAccount;
