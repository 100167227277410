import React from 'react';
import { useHistory } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { Button } from '../../Component/Button';

import styles from './index.module.scss';

function Home() {
  const history = useHistory();

  const goCreateAccount = () => {
    history.push('/create-account');
  };
  const goLogin = () => {
    history.push('/login');
  };

  return (
    <PagePanel>
      <ApplicationPanel hasLogo={true}>
        <div className={styles.main}>
          <Button block onClick={goLogin}>
            Returning Applicant
          </Button>
          <div className={styles['divider-box']}>
            <span className={styles['divider-text']}>Or</span>
          </div>
          <Button block onClick={goCreateAccount}>
            New Applicant
          </Button>
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}

export default Home;
