import request from './request';

enum OpenBankingProvider {
  Plaid = 'PLAID',
}

export function getPlaidLinkToken() {
  return request.post('/links/token/create', null);
}

function saveOpenBankingAuthToken(token: string, provider: OpenBankingProvider) {
  return request.post('/links/authentication/complete', {
    oneTimeToken: token,
    source: provider,
  });
}

export function savePlaidPublicToken(token: string) {
  return saveOpenBankingAuthToken(token, OpenBankingProvider.Plaid);
}
