const RouteUrl = {
  Home: '/',
  CreateAccount: '/create-account',
  PasswordConfirmation: '/password-confirmation',
  Consent: '/consent',
  AccountConnections: '/account-connections',
  TransUnionInfoCollection: '/tu-info-collection',
  PlaidConnection: '/plaid-connection',
  LoanInterest: '/loan-interest',
  Login: '/login',
  KeepInTouch: '/keep-in-touch',
  ForgotPassword: '/forgot-password',
  CreateNewPassword: '/create-new-password',
  Profile: '/profile',
  DeactiveAccount: '/deactive-account',
  Logout: '/logout',
};

export default RouteUrl;
