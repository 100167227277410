import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Button } from '../../Component/Button';
import { Popup } from '../../Component/Popup';
import { resetPassword } from '../../Action/account';
import { ResponseError } from '../../Action/errors';
import { isValidPassword } from '../../Util/validation';
import RouteUrl from '../../Route/RouteUrl';
import { useQuery } from '../../Hook/useQuery';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import FormInput from '../../Component/FormInput';
import { PasswordError } from '../../Constant/ErrorMessage';

import styles from './CreateNewPassword.module.scss';

export default function CreateNewPassword(): JSX.Element {
  const [query] = useQuery();
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const blurPasswordTimer = useRef<NodeJS.Timeout>();
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<string>('');
  const [confirmedPasswordType, setConfirmedPasswordType] = useState<'password' | 'text'>(
    'password'
  );
  const blurConfirmPasswordTimer = useRef<NodeJS.Timeout>();

  const validatePassword = () => {
    let error = '';

    if (password === '') {
      error = PasswordError.empty;
    } else if (!isValidPassword(password)) {
      error = PasswordError.invalid;
    }

    setPasswordError(error);

    return error;
  };

  const validateConfirmedPassword = () => {
    let error = '';

    if (confirmedPassword === '') {
      error = PasswordError.confirm;
    } else if (confirmedPassword !== password) {
      error = PasswordError.mismatch;
    }

    setConfirmedPasswordError(error);

    return error;
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleFocusPassword = (): void => {
    setPasswordError('');
  };
  const handleBlurPassword = (): void => {
    clearTimeout(blurPasswordTimer.current);
    blurPasswordTimer.current = setTimeout(() => {
      validatePassword();
    }, 200);
  };
  const handleTogglePasswordType = (): void => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const handleChangeConfirmedPassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmedPassword(e.target.value);
    setConfirmedPasswordError('');
  };
  const handleFocusConfirmedPassword = (): void => {
    setConfirmedPasswordError('');
  };
  const handleBlurConfirmedPassword = (): void => {
    clearTimeout(blurConfirmPasswordTimer.current);
    blurConfirmPasswordTimer.current = setTimeout(() => {
      validateConfirmedPassword();
    }, 200);
  };
  const handleToggleConfirmedPasswordType = (): void => {
    clearTimeout(blurConfirmPasswordTimer.current);
    setConfirmedPasswordType(confirmedPasswordType === 'password' ? 'text' : 'password');
  };

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<'success' | 'token-invalid' | ''>('');

  const handleSubmit = async (): Promise<void> => {
    const passwordResult = validatePassword();
    const confirmedPasswordResult = validateConfirmedPassword();

    if (passwordResult || confirmedPasswordResult) {
      return;
    }

    setSubmitting(true);

    try {
      await resetPassword({
        password,
        rePassword: confirmedPassword,
        token: query.token,
      });
    } catch (err) {
      const { name, message } = err as ResponseError;

      if (name === 'error.password-not-equal') {
        setConfirmedPasswordError('mismatch');
        return;
      }

      if (name === 'error.reset-password-token-invalid') {
        setResult('token-invalid');
      }

      console.error(message);

      return;
    } finally {
      setSubmitting(false);
    }

    setResult('success');
  };

  return (
    <PagePanel>
      <ApplicationPanel title="Please Create a New Password">
        <div className={styles.form}>
          <FormInput
            value={password}
            isPassword={true}
            label="Password"
            type={passwordType}
            placeholder="Password..."
            errMsg={passwordError}
            handleBlur={handleBlurPassword}
            handleFocus={handleFocusPassword}
            handleChange={handleChangePassword}
            handleTogglePasswordType={handleTogglePasswordType}
          />
          <FormInput
            value={confirmedPassword}
            isPassword={true}
            isConfirmed={true}
            label="Confirm Your Password"
            type={confirmedPasswordType}
            placeholder="Confirm your Password..."
            errMsg={confirmedPasswordError}
            handleBlur={handleBlurConfirmedPassword}
            handleFocus={handleFocusConfirmedPassword}
            handleChange={handleChangeConfirmedPassword}
            handleTogglePasswordType={handleToggleConfirmedPasswordType}
          />
        </div>
        <div className={styles.footer}>
          <Button block loading={submitting} onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </ApplicationPanel>
      <Popup
        visible={result && result !== 'success'}
        type="error"
        title="The link expired, try to get a new one."
        description={
          <div>
            Please return to the <Link to={RouteUrl.ForgotPassword}>Recover Page</Link>
          </div>
        }
      ></Popup>
      <Popup
        visible={result === 'success'}
        type="success"
        title="Your Password has been successfully changed."
        description={
          <div>
            Please return to the <Link to={RouteUrl.Login}>Login Page</Link>
          </div>
        }
      ></Popup>
    </PagePanel>
  );
}
