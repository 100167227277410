import React, { CSSProperties } from 'react';
import { IconCheckmark } from '../Icon/IconCheckmark';
import styles from './index.module.scss';

interface Props {
  label?: any;
  checked?: boolean;
  readOnly?: boolean;
  error?: boolean;
  className?: string;
  containerStyle?: CSSProperties;
  onChange?: (checked: boolean) => void;
}

export function Checkbox({
  label,
  checked,
  error,
  className,
  containerStyle,
  readOnly,
  onChange,
}: Props) {
  const handleClick = () => {
    if (readOnly) {
      return;
    }

    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <div
      className={`${styles.container} ${error ? styles.error : ''} ${className || ''}`}
      style={{
        ...containerStyle,
      }}
      onClick={handleClick}
    >
      <div className={`${styles.checkbox} ${checked ? styles.checked : ''}`}>
        {checked ? (
          <span className={styles.checkmark}>
            <IconCheckmark />
          </span>
        ) : null}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}
