import { IconWarning } from '../../../Component/Icon/IconWarning';

import styles from './index.module.scss';

interface Props {
  type: 'text' | 'number' | 'email' | 'date';
  value: string;
  label?: string;
  serial?: string;
  onChange?: any;
  isRequired: boolean;
  errType: string;
}

export const Input = ({ serial, label, isRequired, type, errType, value, onChange }: Props) => {
  const placeHolder = type === 'date' ? 'YYYY-MM-DD' : `${label}...`;
  const required = isRequired ? 'Required' : 'Optional';
  const emptyMsg = `Please Input Your ${label}.`;
  const isError = errType.length > 0;

  return (
    <div className={`${styles['form-item']} ${isError ? styles['form-item-error'] : ''}`}>
      <label className={styles['form-label']}>
        {serial} {label} <span className={styles.divider}>|</span>
        <span>{required}</span>
      </label>
      <div className={styles['form-content']}>
        <input
          className={styles['form-input']}
          type="text"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeHolder}
        />
        {isError && (
          <div className={styles['form-error']}>
            <i className={styles['form-input-error-icon']}>
              <IconWarning />
            </i>
            {errType === 'EMPTY' && (
              <span className={styles['form-input-error-text']}>{emptyMsg}</span>
            )}
            {errType === 'UNMATCH' && (
              <span className={styles['form-input-error-text']}>
                This information doesnt seem to match.
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
