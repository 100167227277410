import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { Button } from '../../Component/Button';
import { Step } from '../../Component/Step';
import { Checkbox } from '../../Component/Checkbox';
import { Spin } from '../../Component/Spin';
import RouteUrl from '../../Route/RouteUrl';
import { getConsentContent, acceptConsent } from '../../Action/consent';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';

import styles from './index.module.scss';

export default function Consent(): JSX.Element {
  const history = useHistory();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);

    getConsentContent('FAIRVIEW')
      .then((response) => {
        setContent(response.content);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleCheck = (checked: boolean) => {
    setChecked(checked);
    setCheckedError(false);
  };

  const handleSubmit = async () => {
    if (!checked) {
      setCheckedError(true);
      return;
    }

    setCheckedError(false);

    setSubmitting(true);

    try {
      await acceptConsent('FAIRVIEW');
    } catch (err) {
      console.error(err);
      return;
    } finally {
      setSubmitting(false);
    }

    history.push(RouteUrl.AccountConnections);
  };

  return (
    <PagePanel>
      <ApplicationPanel
        title={'Please Accept the T&C/ Consent Agreement and Privacy Policy'}
        hasLogo={false}
      >
        <Spin spinning={loading}>
          <div className={styles.content} dangerouslySetInnerHTML={{__html: content}}></div>
          <div className={`${styles['choice-container']}  ${checkedError ? styles.error : ''}`}>
            <Checkbox
              className={styles['choice-content']}
              label={
                <div className={styles['choice-tips']}>
                  Do you consent to the ToS / Consent Agreement?
                </div>
              }
              checked={checked}
              error={checkedError}
              onChange={handleCheck}
            />
          </div>
          <Button type="primary" block loading={submitting} onClick={handleSubmit}>
            Next
          </Button>
          <div className={styles.step}>
            <Step total={4} current={2} />
          </div>
        </Spin>
      </ApplicationPanel>
    </PagePanel>
  );
}
