const PhoneOTPNumberLength = 6; // phone otp valid length

const EmailOTPNumberLength = 6; // phone otp valid length

const PhoneNumberLength = 10;

const CodeLength = 7;

// exclude 0,1,2,9, o,l,i,q,z
// export function isValidCode(code: string) {
//   // Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
//   return /^(?=.{7}$)(?:[3-8]*[a-hjkmnpr-yA-HJKMNPR-Y]){5}[3-8]*$/.test(code);
// }

export function isValidCode(code: string) {
  // Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
  const reg = new RegExp(`^[a-zA-Z0-9]{${CodeLength}}$`, 'g');
  return reg.test(code);
}

export function isValidPhone(phone: string) {
  const reg = new RegExp(`^\\d{${PhoneNumberLength}}$`, 'g');
  return reg.test(phone);
}

export function isValidPhoneOTP(code: string) {
  const reg = new RegExp(`^\\d{${PhoneOTPNumberLength}}$`, 'g');
  return reg.test(code);
}

export function isValidEmailOTP(code: string) {
  const reg = new RegExp(`^\\d{${EmailOTPNumberLength}}$`, 'g');
  return reg.test(code);
}

// https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
const emailReg = new RegExp(
  // eslint-disable-next-line
  '(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])'
);
export function isValidEmail(email: string) {
  return emailReg.test(email);
}

export function isValidPassword(password: string) {
  const atLeastLength = password.length >= 8;
  const atLeastOneNumber = /^(?=.*[0-9])/.test(password);
  const atLeastOneLetter = /^(?=.*[a-zA-Z])/.test(password);

  return atLeastLength && atLeastOneNumber && atLeastOneLetter;
}
