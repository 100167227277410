import { useState, useEffect, useRef } from 'react';

export function useCountdown(seconds: number): [number, () => void, () => void] {
  const [time, setTime] = useState<number>(0);
  const [status, setStatus] = useState<'running' | 'paused'>('paused');
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (status === 'paused') {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      const value = time - 1;

      if (value <= 0) {
        setStatus('paused');
      }

      setTime(value);
    }, 1000);

    return () => clearTimeout(timeoutRef.current);
  }, [time, status]);

  const start = () => {
    setStatus('running');
    setTime(seconds);
  };

  const reset = () => {
    setStatus('paused');
    setTime(0);
  };

  return [time, start, reset];
}
