export const allErrors = [
  // otp
  'error.illegal-email',
  'error.illegal-phone-number',
  'error.email-has-been-taken',
  'error.otp-email-is-taken',
  'error.phone-number-has-been-taken',
  'error.otp-phone-is-taken',
  'error.illegal-otp',
  'error.timed-out-code',
  'error.get-otp-too-frequency',

  // password creation
  'error.create-account-failure',
  'error.password-not-equal',
  'error.email-registered-error',

  // login
  'error.email-or-password-invalid',

  // forgot password
  'error.email-send-error',
  'error.reset-password-token-invalid',
] as const;

export type ResponseErrorCode = typeof allErrors[number];

export interface ResponseError extends Error {
  name: ResponseErrorCode;
}

export const errorMessages: Partial<Record<ResponseErrorCode, string>> = {
  // otp
  'error.illegal-email': 'Please Input a Valid Email Address',
  'error.illegal-phone-number': 'Please Input a Valid Phone Number',
  'error.email-has-been-taken': 'Email address is taken',
  'error.otp-email-is-taken': 'Email address is taken',
  'error.phone-number-has-been-taken': 'Phone number is taken',
  'error.otp-phone-is-taken': 'Phone number is taken',
  'error.illegal-otp': 'This Code Seems To Be Incorrect',
  'error.timed-out-code': 'This One Time Password has expired',

  // password creation
  'error.create-account-failure': 'Create account failed',
  'error.password-not-equal': 'Sorry Your Password Does Not Match',
  'error.email-registered-error': 'Email address is taken',

  // login
  'error.email-or-password-invalid': 'This email address and password do not match up',

  // forgot password
  'error.email-send-error': 'Fail to send recovery email',
};
