import { useLocation, useHistory } from 'react-router-dom';

import { parseUrlQuery, stringify } from '../Util/url';

export function useQuery(): [Record<string, string>, (value: Record<string, string>) => void] {
  const location = useLocation();
  const history = useHistory();
  const query = parseUrlQuery(location.search);

  const setQuery = (value: Record<string, string>): void => {
    const nextQuery = stringify({ ...query, ...value });

    history.push(`${location.pathname}?${nextQuery}`);
  };

  return [query, setQuery];
}
