import React from 'react';

import styles from './index.module.scss';

interface Props {
  total: number;
  current: number;
}

export function Step({ total, current }: Props) {
  const all = Array.from({ length: total });

  const getClass = (index) => {
    return index < current - 1
      ? 'large-item-previous'
      : index < current
      ? styles['large-item-active']
      : '';
  };

  return (
    <div className={styles.container}>
      <div className={styles['large-box']}>
        {all.map((_, i) => (
          <div key={i} className={`${styles['large-item']} ${styles[getClass(i)]}`}>
            <div className={styles['large-item-num-box']}>
              <div className={styles['large-item-num']}>{i + 1}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles['mobile-box']}>
        {all.map((_, i) => (
          <div
            key={i}
            className={`${styles['mobile-item']} ${
              i < current ? styles['mobile-item-active'] : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
}
