import request from './request';

export function getAccountConnectionStatus() {
  return request.get('/data-collection/status');
}

export function saveTransUnionInfo(data) {
  return request.post('/data-collection/confirm-validate-details', data);
}

export function getBankAccounts() {
  return request.get('/data-collection/bank-accounts');
}
