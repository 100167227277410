import React, { ReactNode } from 'react';
import TUIcon from '../../../Assets/images/TU-icon.png';
import plaidIcon from '../../../Assets/images/plaid-icon.png';
import { IconConnected } from '../../../Component/Icon/IconConnected';

import styles from './index.module.scss';

interface Props {
  title: string;
  children: ReactNode;
  iconType: string;
  connectStatus: boolean;
}

function ConnectBox({ iconType, connectStatus, title, children }: Props) {
  return (
    <div className={`${styles.container} ${connectStatus ? styles.connected : ''}`}>
      <div className={styles.connection}>
        <div className={styles['connect-info']}>
          {iconType === 'TU' && <img className={styles.icon} src={TUIcon} alt="tu" />}
          {iconType === 'PLAID' && <img className={styles.icon} src={plaidIcon} alt="plaid" />}
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles['connect-des']}>{children}</div>
      </div>
      {connectStatus ? (
        <div className={styles.footer}>
          <i>
            <IconConnected />
          </i>
          <span>Your account has been connected</span>
        </div>
      ) : (
        <div className={styles.footer}>Your account has not been connected</div>
      )}
    </div>
  );
}

export default ConnectBox;
