import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RouteUrl from '../../Route/RouteUrl';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { IconReminder } from '../../Component/Icon/IconReminder';
import FormInput from '../../Component/FormInput';
import { Button } from '../../Component/Button';
import { PasswordError } from '../../Constant/ErrorMessage';
import { isValidPassword } from '../../Util/validation';
import { Popup } from '../../Component/Popup';
import { deleteAccount } from '../../Action/account';

import styles from './index.module.scss';

export default function DeactiveAccount(): JSX.Element {
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const blurPasswordTimer = useRef<NodeJS.Timeout>();
  const history = useHistory();

  const goToProfile = () => {
    history.push(RouteUrl.Profile);
  };

  const getPasswordValidationResult = (value: string) => {
    if (!value) {
      return PasswordError.empty;
    }

    const valid = isValidPassword(value);

    if (!valid) {
      return PasswordError.invalid;
    }

    return '';
  };

  const validatePassword = () => {
    const result = getPasswordValidationResult(password);

    setPasswordError(result);

    return !result;
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleFocusPassword = (): void => {
    setPasswordError('');
  };
  const handleBlurPassword = (): void => {
    clearTimeout(blurPasswordTimer.current);
    blurPasswordTimer.current = setTimeout(() => {
      validatePassword();
    }, 200);
  };

  const handleTogglePasswordType = (): void => {
    clearTimeout(blurPasswordTimer.current);
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const deactiveAccount = (): void => {
    if (!validatePassword()) return;
    setIsLoading(true);
    deleteAccount({ password })
      .then(() => {
        setIsVisible(true);

        setTimeout(() => {
          history.push(RouteUrl.Home);
        }, 1000 * 2);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PagePanel>
      <ApplicationPanel title={''}>
        <div className={styles.container}>
          <div className={styles.reminder}>
            <IconReminder />
          </div>
          <div className={styles.info}>
            <p>
              Once you have deactivated your account,you would not be able to login our platform
              with this account.
            </p>
            <p>
              Meanwhile,all the information including the registered phone number will also be
              deactivated.
            </p>
          </div>
          <FormInput
            label="Confirm your Password"
            type={passwordType}
            value={password}
            placeholder="Confirm your Password..."
            isPassword={true}
            handleChange={handleChangePassword}
            handleBlur={handleBlurPassword}
            handleFocus={handleFocusPassword}
            errMsg={passwordError}
            handleTogglePasswordType={handleTogglePasswordType}
          />
          <div className={styles.buttons}>
            <Button block onClick={goToProfile} className={styles.back}>
              Back
            </Button>
            <Button block loading={isLoading} onClick={deactiveAccount}>
              Confirm
            </Button>
          </div>
        </div>
        <Popup
          type="success"
          visible={isVisible}
          title="Successfully deactivated"
          description="Hope to see you again"
        />
      </ApplicationPanel>
    </PagePanel>
  );
}
