import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RouteUrl from '../../Route/RouteUrl';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import { PagePanel } from '../../Component/Panel/PagePanel';
import { IconEmail } from '../../Component/Icon/IconEmail';
import { IconPhone } from '../../Component/Icon/IconPhone';
import { removeToken } from '../../Util/token';
import { getProfile } from '../../Action/account';
import { LoadingOverlay } from '../../Component/LoadingOverlay';

import styles from './index.module.scss';

export default function Profile(): JSX.Element {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getProfile()
      .then((data) => {
        setEmail(data.email);
        setPhone(data.phone);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const logOut = () => {
    removeToken();
  };

  return (
    <PagePanel>
      <ApplicationPanel title={''}>
        {!isLoading && (
          <div className={styles.container}>
            <div className={styles.text}>
              <br/>
            </div>
            <div className={styles.info}>
              <div className={styles.email}>
                <IconEmail />
              </div>
              <span className={styles.text}>{email}</span>
            </div>
            <div className={styles.info}>
              <div className={styles.phone}>
                <IconPhone />
              </div>
              <span className={styles.text}>{`GB+44 ${phone}`}</span>
            </div>
            <div className={styles.links}>
              <div className={styles.link}>
                <Link to={RouteUrl.DeactiveAccount}>Deactivate Account</Link>
              </div>
              <div className={styles.link}>
                <Link onClick={logOut} to={RouteUrl.Login}>
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        )}
        {isLoading && <LoadingOverlay />}
      </ApplicationPanel>
    </PagePanel>
  );
}
