import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PagePanel } from '../../Component/Panel/PagePanel';
import { Button } from '../../Component/Button';
import { Step } from '../../Component/Step';
import { createAccount } from '../../Action/account';
import { useCreateAccountSession } from '../../Hook/useSessionStorage';
import { isValidPassword } from '../../Util/validation';
import routeUrl from '../../Route/RouteUrl';
import { setToken } from '../../Util/token';
import { ApplicationPanel } from '../../Component/Panel/ApplicantPanel';
import FormInput from '../../Component/FormInput';
import { PasswordError } from '../../Constant/ErrorMessage';

import styles from './index.module.scss';

export default function PasswordConfirmation(): JSX.Element {
  const history = useHistory();
  const [session, setSession] = useCreateAccountSession();
  const [password, setPassword] = useState<string>('145a3r44H');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const blurPasswordTimer = useRef<NodeJS.Timeout>();
  const [confirmedPassword, setConfirmedPassword] = useState<string>('145a3r44H');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<string>('');
  const [confirmedPasswordType, setConfirmedPasswordType] = useState<'password' | 'text'>(
    'password'
  );
  const blurConfirmPasswordTimer = useRef<NodeJS.Timeout>();

  const validatePassword = () => {
    let error = '';

    if (password === '') {
      error = PasswordError.empty;
    } else if (!isValidPassword(password)) {
      error = PasswordError.invalid;
    }

    setPasswordError(error);

    return error;

  };

  const validateConfirmedPassword = () => {
    let error = '';

    if (confirmedPassword === '') {
      error = PasswordError.confirm;
    } else if (confirmedPassword !== password) {
      error = PasswordError.mismatch;
    }

    setConfirmedPasswordError(error);

    return error;
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleFocusPassword = (): void => {
    setPasswordError('');
  };
  const handleBlurPassword = (): void => {
    clearTimeout(blurPasswordTimer.current);
    blurPasswordTimer.current = setTimeout(() => {
      validatePassword();
    }, 200);
  };
  const handleTogglePasswordType = (): void => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };
  const handleChangeConfirmedPassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmedPassword(e.target.value);
    setConfirmedPasswordError('');
  };
  const handleFocusConfirmedPassword = (): void => {
    setConfirmedPasswordError('');
  };
  const handleBlurConfirmedPassword = (): void => {
    clearTimeout(blurConfirmPasswordTimer.current);
    blurConfirmPasswordTimer.current = setTimeout(() => {
      validateConfirmedPassword();
    }, 200);
  };
  const handleToggleConfirmedPasswordType = (): void => {
    clearTimeout(blurConfirmPasswordTimer.current);
    setConfirmedPasswordType(confirmedPasswordType === 'password' ? 'text' : 'password');
  };

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    const passwordResult = validatePassword();
    const confirmedPasswordResult = validateConfirmedPassword();

    if (passwordResult || confirmedPasswordResult) {
      return;
    }

    let token = '';

    setSubmitting(true);

    try {
      token = await createAccount({
        emailAddress: session.email,
        phoneNumber: session.phone,
        password,
        rePassword: confirmedPassword,
        isDemo: true,
      });
    } catch (err) {
      console.error(err.message);

      return;
    } finally {
      setSubmitting(false);
    }

    setToken(token);
    history.push(routeUrl.Consent);
  };

  useEffect(() => {
    if (!session.email) {
      setSession({
        type: 'email',
      });
      history.replace(routeUrl.CreateAccount);

      return;
    }

    if (!session.phone) {
      setSession({
        type: 'phone',
      });
      history.replace(routeUrl.CreateAccount);

      return;
    }
  }, [session, setSession, history]);

  return (
    <PagePanel>
      <ApplicationPanel title="Please Create a Password">
        <div className={styles.form}>
          <FormInput
            label="Password"
            type={passwordType}
            isPassword={true}
            value={password}
            placeholder="Password..."
            errMsg={passwordError}
            handleTogglePasswordType={handleTogglePasswordType}
            handleChange={handleChangePassword}
            handleBlur={handleBlurPassword}
            handleFocus={handleFocusPassword}
          />
          <FormInput
            label="Confirm Your Password"
            type={confirmedPasswordType}
            isPassword={true}
            isConfirmed={true}
            value={confirmedPassword}
            placeholder="Confirm your Password..."
            errMsg={confirmedPasswordError}
            handleTogglePasswordType={handleToggleConfirmedPasswordType}
            handleChange={handleChangeConfirmedPassword}
            handleBlur={handleBlurConfirmedPassword}
            handleFocus={handleFocusConfirmedPassword}
          />
        </div>
        <div className={styles.footer}>
          <Button block loading={submitting} onClick={handleSubmit}>
            Next
          </Button>
          <div className={styles['step-box']}>
            <Step total={4} current={1} />
          </div>
        </div>
      </ApplicationPanel>
    </PagePanel>
  );
}
