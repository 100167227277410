import request from './request';

export type ConsentProvider = 'FAIRVIEW';

interface GetConsentResponse {
  content: string;
}

export function getConsentContent(provider: ConsentProvider): Promise<GetConsentResponse> {
  return request.get('/consents/content', {
    params: {
      provider,
    },
  });
}

export function acceptConsent(provider: ConsentProvider): Promise<void> {
  return request.put(`/consents/accept`, { provider: provider });
}
