import { useState } from 'react';

export function useSessionStorage(key: string, initialValue: any): [any, (value: any) => void] {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);

    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  const setSessionStorage = (value: any): void => {
    setValue(value);
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  return [value, setSessionStorage];
}

export function useCreateAccountSession(): [any, (value: any) => void] {
  const [session, setSessionStorage] = useSessionStorage('createAccount', {});

  const updateSessionStorage = (value: any, replace = false): void => {
    if (replace) {
      setSessionStorage(value);
    } else {
      setSessionStorage({ ...session, ...value });
    }
  };

  return [session, updateSessionStorage];
}
